<template>
  <div>
  <div class="home">
    <div class="container">
      <div class="free-poker-header">
        <h1>Scrum poker made simple and <span>free</span>.</h1>
      </div>
      <div class="start-game">
        <button class="button" :class="{ disabled: clickedStart }"
                @click="startGame()">
          <span v-if="!clickedStart">Create room</span>
          <svg
              v-if="clickedStart"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="24px"
              height="30px"
              viewBox="0 0 24 30"
              style="enable-background: new 0 0 50 50"
              xml:space="preserve"
          >
            <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
              <animate
                  attributeName="opacity"
                  attributeType="XML"
                  values="0.2; 1; .2"
                  begin="0s"
                  dur="0.6s"
                  repeatCount="indefinite"
              />
              <animate
                  attributeName="height"
                  attributeType="XML"
                  values="10; 20; 10"
                  begin="0s"
                  dur="0.6s"
                  repeatCount="indefinite"
              />
              <animate
                  attributeName="y"
                  attributeType="XML"
                  values="10; 5; 10"
                  begin="0s"
                  dur="0.6s"
                  repeatCount="indefinite"
              />
            </rect>
            <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
              <animate
                  attributeName="opacity"
                  attributeType="XML"
                  values="0.2; 1; .2"
                  begin="0.15s"
                  dur="0.6s"
                  repeatCount="indefinite"
              />
              <animate
                  attributeName="height"
                  attributeType="XML"
                  values="10; 20; 10"
                  begin="0.15s"
                  dur="0.6s"
                  repeatCount="indefinite"
              />
              <animate
                  attributeName="y"
                  attributeType="XML"
                  values="10; 5; 10"
                  begin="0.15s"
                  dur="0.6s"
                  repeatCount="indefinite"
              />
            </rect>
            <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
              <animate
                  attributeName="opacity"
                  attributeType="XML"
                  values="0.2; 1; .2"
                  begin="0.3s"
                  dur="0.6s"
                  repeatCount="indefinite"
              />
              <animate
                  attributeName="height"
                  attributeType="XML"
                  values="10; 20; 10"
                  begin="0.3s"
                  dur="0.6s"
                  repeatCount="indefinite"
              />
              <animate
                  attributeName="y"
                  attributeType="XML"
                  values="10; 5; 10"
                  begin="0.3s"
                  dur="0.6s"
                  repeatCount="indefinite"
              />
            </rect>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="content">
    <h1>
      Scrum Poker Methodology: A Unique Approach to Agile Estimation
    </h1>
    <p>
      Scrum Poker, also known as Planning Poker, is a collaborative estimation
      technique used by Agile teams to assess the effort required to complete
      tasks or user stories. This technique blends consensus-based
      decision-making with gamification, helping teams to arrive at accurate
      and unbiased estimates while encouraging active participation from all
      team members.
    </p>
    <h2>
      The Essence of Scrum Poker
    </h2>
    <p>
      Scrum Poker is rooted in the Agile principle of collective wisdom, where
      each team member's perspective is valued. The process begins with the
      Product Owner presenting a user story or task that needs to be
      estimated. Team members, equipped with a set of cards (usually Fibonacci
      sequence numbers or T-shirt sizes), independently select a card that
      represents their estimate of the effort required.
    </p>
    <p>
      Once everyone has made their selection, the cards are revealed
      simultaneously. This simultaneous reveal is crucial as it prevents
      anchoring, where one person's estimate might unduly influence others. If
      all estimates are in close agreement, the team discusses any minor
      differences and reaches a consensus on the final estimate. However, if
      there are significant variations, a deeper discussion ensues to
      understand the different perspectives.
    </p>
    <h2>
      Why Scrum Poker?
    </h2>
    <p>Scrum Poker offers several unique advantages:</p>
    <ul>
      <li><strong>Avoiding Bias</strong>: By revealing estimates
        simultaneously, it reduces the risk of anchoring and groupthink.
      </li>
      <li><strong>Encouraging Participation</strong>: Every team member,
        regardless of their role or experience, is encouraged to contribute
        their perspective.
      </li>
      <li><strong>Fostering Collaboration</strong>: The discussions that
        follow divergent estimates often lead to a deeper understanding of the
        task at hand, ensuring that all potential challenges are considered.
      </li>
      <li><strong>Efficient Estimation</strong>: the gamified nature of Scrum
        Poker keeps the estimation process engaging, ensuring that it doesn’t
        become a tedious task.
      </li>
    </ul>
    <h2>Practical Implementation</h2>
    <p>To implement Scrum Poker effectively:</p>

    <ul>
      <li>Prepare the Cards: Ensure that all team members have access to a
        standard set of cards, either physical or digital.
      </li>
      <li>Present the Story: The Product Owner or Scrum Master should clearly
        explain the user story, ensuring that all necessary details are
        provided.
      </li>
      <li>Discuss and Estimate: Allow time for questions and clarifications
        before making the first round of estimates.
      </li>
      <li>Reveal and Discuss: After the reveal, discuss any significant
        discrepancies, focusing on the reasoning behind different estimates.
      </li>
      <li>Reach Consensus: Continue the discussion until the team agrees on an
        estimate that reflects the collective understanding of the task.
        In essence, Scrum Poker is not just about numbers; it's about
        fostering a shared understanding and alignment within the team. It
        turns the estimation process into an inclusive, transparent, and
        engaging activity, crucial for the success of any Agile project.
      </li>
    </ul>
  </div>
  </div>
</template>

<script setup lang="ts">
import router from "@/router";
import {io} from "socket.io-client";
import {ref} from 'vue';
import {useGameEngine} from "@/composables/useGameEngine";

const {socket, setSocket} = useGameEngine();
const clickedStart = ref(false);
const hasStarted = ref(false);

function startGame() {
  clickedStart.value = true;
  setTimeout(() => {
    if (!hasStarted.value) {
      alert("Looks like there's a problem connecting you to the server 😕");
    }
  }, 5000);
  const newSocket = io(process.env.VUE_APP_SERVER);
  setSocket(newSocket);
  socket.value.on("room", (roomId: string) => {
    hasStarted.value = true;
    router.push({path: `/game/${roomId}`});
  });
}
</script>

<style scoped lang="scss">

.content {
  text-align: left;
  margin-top: 5rem;
  padding: 20px;
}

.home {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin-top: 8rem;
}


.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 800px;
}

.free-poker-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 80%;
  width: 100%;

  h1 {
    user-select: none;
    font-size: 3.2em;

    span {
      color: #54e8dd;
      background: black;
      border-radius: 10px;
      width: 7rem;
      display: inline-block;
    }
  }
}

.start-game {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  position: relative;
}

.button {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 9999;
  width: 320px;
  height: 80px;
  background: #f3f0f1;
  border-radius: 32px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
  6px 6px 10px rgba(0, 0, 0, 0.2);
  color: #161b1f;

  &:hover {
    opacity: 0.3;
    box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
    6px 6px 10px rgba(0, 0, 0, 0.2);
  }

  &:active {
    opacity: 1;
    box-shadow: inset -4px -4px 8px rgba(255, 255, 255, 0.5),
    inset 8px 8px 16px rgba(0, 0, 0, 0.1);
  }

  span {
    font-family: "Montserrat", sans-serif;
    font-size: 26px;
    font-weight: semibold;
  }
}

.disabled {
  opacity: 1;
  box-shadow: inset -4px -4px 8px rgba(255, 255, 255, 0.5),
  inset 8px 8px 16px rgba(0, 0, 0, 0.1);

  &:hover {
    opacity: 1;
    box-shadow: inset -4px -4px 8px rgba(255, 255, 255, 0.5),
    inset 8px 8px 16px rgba(0, 0, 0, 0.1);
  }
}

svg rect {
  fill: #54e8dd;
}


@media only screen and (max-width: 800px) {

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 800px;
  }

  .free-poker-header {
    width: 90%;
    align-items: flex-end;
  }

  .start-game {
    align-items: flex-start;
  }
}
</style>
